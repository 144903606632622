<!--
 * @Author: your name
 * @Date: 2020-12-18 13:47:12
 * @LastEditTime: 2021-01-13 15:05:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\About.vue
-->
<template>
  <div>
    <div class="title">发起售后</div>
    <!-- 表单 -->
    <div v-if="ok">
      <van-form @submit="onSubmit" :show-error="false" colon>
        <van-field
          v-model="form.accountName"
          required
          name="accountName"
          label="用户姓名"
          maxlength="20"
          placeholder="用户姓名"
          :rules="[{ required: true, message: '请填写用户姓名' }]"
        />
        <van-field
          v-model="form.accountPhone"
          required
          name="accountPhone"
          label="联系电话"
          placeholder="联系电话"
          type="number"
          maxlength="11"
          :rules="[{ pattern, message: '请填写正确的电话' }]"
        />
        <van-field
          v-model="form.accountSN"
          required
          name="accountSN"
          label="SN号"
          placeholder="SN号"
          maxlength="14"
          :rules="[{ validator, message: '请填写正确的SN号' }]"
        />
        <van-field
          v-model="form.problemDesc"
          name="problemDesc"
          rows="2"
          autosize
          required
          label="问题描述"
          type="textarea"
          maxlength="200"
          placeholder="请输入问题描述"
          :rules="[{ required: true, message: '请输入问题描述' }]"
          show-word-limit
        />

        <van-field
          v-model="form.device"
          name="device"
          label="主机型号"
          placeholder="主机型号"
        />
        <van-field
          v-model="form.shop"
          name="shop"
          label="购买商店"
          placeholder="购买商店"
        />
        <van-field
          v-model="form.dealerPhone"
          name="dealerPhone"
          label="经销商电话"
          placeholder="经销商电话"
          type="number"
          maxlength="11"
        />
        <van-field
          readonly
          clickable
          name="buyDate"
          :value="form.buyDate"
          label="购买日期"
          placeholder="点击选择日期"
          @click="showCalendar = true"
        />
        <van-field
          v-model="form.address"
          name="address"
          label="通讯地址"
          placeholder="通讯地址"
        />

        <div style="margin: 16px;">
          <van-button
            round
            block
            :loading="loading"
            :disabled="loading"
            loading-text="提交中..."
            type="info"
            native-type="submit"
          >
            提交
          </van-button>
        </div>
      </van-form>

      <van-calendar
        v-model="showCalendar"
        @confirm="onConfirm"
        color="#1989fa"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </div>

    <div class="jieguo" v-else>
      <!-- <van-empty description="提交成功!" /> -->
      <li>提交成功！</li>
      <p>客服会在3个工作日内与您联系确认售后服务要求</p>
    </div>

    <!-- 分割 -->
    <div class="hr"></div>

    <div class="title">售后须知</div>

    <div class="p">
      <p>·此保修服务只适用于装箱清单中主机和配件的三包服务。</p>
      <p>
        ·客服会在3个工作日内与您联系确认售后服务要求，寄出
        产品前，请您备份数据信息，退出相关账户信息，附带购 机发票复印件。
      </p>
      <p>·人为损坏属于非三包范围，具体三包范围请参见“服务政 策”中的规定。</p>
      <p>
        · 三包期内性能故障导致的产品退换货和维修服务，将由锶
        想公司承担快递费用，快递纸质发票随货寄出，我们将依
        据快递发票为您实报实销。
      </p>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { postMk } from "@/utils/web";
export default {
  data() {
    return {
      ok: true,

      // 表单内容
      form: {
        accountName: "",
        accountPhone: "",
        accountSN: "",
        problemDesc: "",
        device: "",
        shop: "",
        dealerPhone: "",
        buyDate: "",
        address: "",
      },

      pattern: /^1[0-9]{10}$/,
      loading: false,
      showCalendar: false,
      minDate: new Date(2017, 0, 1),
      maxDate: new Date(),
    };
  },
  methods: {
    onSubmit(values) {
      values.addressee = "harrison@huahuico.com";
      // values.addressee = "893264950@qq.com";
      this.loading = true;
      this.$post(postMk, values)
        .then((response) => {
          this.loading = false;
          if (response.error_code == "200") {
            this.ok = false;
            Toast(response.error_msg);
          } else {
            this.ok = true;
            Toast(response.error_msg);
          }
        })
        .catch(() => {
          this.loading = false;
          console.log("err");
        });
    },
    onConfirm(date) {
      let year = this.addZer(date.getMonth() + 1);
      let day = this.addZer(date.getDate());
      this.form.buyDate = `${date.getFullYear()}-${year}-${day}`;
      this.showCalendar = false;
    },
    addZer(str) {
      return str <= 9 ? `0${str}` : str;
    },

    validator(val) {
      if (val.length < 14) {
        return false;
      } else if (val.substring(0, 2) !== "ST") {
        return false;
      } else if (!/^[0-9a-zA-Z_]*$/.test(val)) {
        return false;
      } else {
        return true;
      }
      // return /1\d{10}/.test(val);
    },
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
.title {
  margin: 30px;
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 30px;
}
.hr {
  background: #f5f5f5;
  width: 100%;
  height: 30px;
}
.van-calendar__header {
  width: 100%;
}
.p {
  font-size: 26px;
  text-align: left;
  padding: 0 30px 50px;
  color: #666;
}
.p p {
  margin: 0;
  margin-bottom: 15px;
}

.jieguo {
  padding: 120px 0;
  color: #1989fa;
}
.jieguo li {
  font-size: 42px;
  font-weight: 600;
}

.jieguo p {
  font-size: 26px;
}
</style>
