/*
 * @Author: your name
 * @Date: 2020-12-02 00:35:09
 * @LastEditTime: 2021-01-13 15:04:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\demo\vue\vant\src\utils\web.js
 */

// 总地址
let url = "/cncapi";

// 表单提交
let postMk = `${url}/aftermarket`;

export { postMk };
